// Import fonts from google
// 2016-12-05 2rm changed include to default.ascx (caused issues with ClientDependency)
// @import url(https://fonts.googleapis.com/css?family=Maven+Pro:400,500,700);

body,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-family-sans-serif;
	span {
		font-size: 1rem;
					color: $primary;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	br {
		@include media-breakpoint-down(md) {
			content: "";
		}

		&:after {
			@include media-breakpoint-down(md) {
				content: " ";
			}
		}
	}
}

body {
	strong {
		font-weight: 700;
	}

	ul {
		list-style-type: disc;
	}
	.ol {
    list-style: none;
    padding-left: $spacer;
    counter-reset: circle-counter;
		.li {
			counter-increment: circle-counter;
			margin-bottom: $spacer*.25;
			&:before {
					content: counter(circle-counter);
					background-color: $primary;
					width: $font-size-base*1.5;
					height: $font-size-base*1.5;
					border-radius: 50%;
					display: inline-block;
					font-size: $font-size-base*.75;
					line-height: $font-size-base*1.5;
					color: $primary;
					text-align: center;
					margin-right: $spacer*.5;
					position: relative;
					top: -2px;
			}
			&:last-child {
					margin-bottom: 0;
			}
			.number-circle-list--list-item {
					margin-left: $spacer*.25;
			}
	}
}

.alert-dark {
	background-color: $gray-900;
	color:#FFF;
	border-color: $gray-900;
}
.alert {
h4 {
	color: $secondary;
}
}
}

   //animations
	// [data-aos="clipping"] {
	// &.aos-animate {
	// 	animation: text-clip 1s 0.5s cubic-bezier(0.5, 0, 0.1, 1) both;
	// }
	// }
	// @keyframes text-clip {
	// 	from {
	// 		clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
	// 		opacity: 0;
	// 	}
	// 	to {
	// 		clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
	// 		opacity: 1;
	// 	}
	// }
	// @keyframes fadeInUp{
	// 	from {
	// 		transform: translate3d(0,40px,0);
	// 		opacity: 0;
	// }
	// to {
	// 		transform: translate3d(0,0,0);
	// 		opacity: 1;
	// }
	// }
