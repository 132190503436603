/*layout fullscreen*/
html {
  scroll-padding-top: 200px;
  body.#{$prefix}-variation-fullscreen,
  // when using wrapper instead of body
  div#cre8magic-root.#{$prefix}-variation-fullscreen {
    background-color: $body-bg;

    ##{$prefix}-page-header-pane {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
      &.pane-is-empty {
        min-height: 80px;
      }
      &.container {
        position:static;
        z-index: 1;
      }
    }
    footer##{$prefix-footer} {
      background:$gray-900;
    }
    
  }
}
