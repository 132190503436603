﻿html {
  min-height: 100vh;
  //Small changes because oqtane doesn't have a form wrapper like DNN but has a div wrapper
  body {
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 100vh;

    main##{$prefix}-page-main {
      flex: 1 0 auto;
    }

    .container, .container-fluid {
      position:relative;
      z-index: 1;
      @include media-breakpoint-up(xxl) {
        padding-left: 45px;
        padding-right: 45px;
      }
    }
    .form-check-input, .form-control {
      background:white;
      color: $body-bg;
    }
  }
}