.module-unpublished {
  position: relative;
  &.#{$prefix-container}-admin {
    &:before, &:after {
      display: none;
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: 22px;
    top: 60px;
    margin-top: -15px;
    text-align: center;
    width: 10px;
    display: block;
    height: 10px;
    background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%);
    border-radius: 50%;
    filter:blur(2px);
    animation: scaling 0.5s infinite alternate;
  }
  &:after {
    content: "Unpublished";
    position: absolute;
    left: 120px;
    top: 65px;
    margin-left: -100px;
    text-align: center;
    width: 30px;
    display: block;
    font-size: 10px;
    background-image: linear-gradient(0deg, #00C9FF, #92FE9D);
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-orientation: mixed;
    background-size: 100%;
    background-repeat: repeat;
    text-orientation: sideways-right;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(0,0,0,0);
    -moz-background-clip: text;
    -moz-text-fill-color: rgba(0,0,0,0);

  }
  @keyframes scaling {
    From {
        transform: scale(1.0);
    }
    To {
        transform: scale(1.2);
    }
  }
  
}