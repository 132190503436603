﻿/* Oqtane Styles */
//Those styles came with the skin and are needed for some oqtane internal stylings

body  > div:first-of-type, 
// 2dm: Ability to set the size on body OR div
#cre8magic-root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.table > :not(caption) > * > * {
	box-shadow: none;
}

.table .form-control {
	background-color: #ffffff !important;
	border-width: 0.5px !important;
	border-bottom-color: #ccc !important;
}

.table .form-select {
	background-color: #ffffff !important;
	border-width: 0.5px !important;
	border-bottom-color: #ccc !important;
}

.table .btn-primary {
	background-color: var(--bs-primary);
}

.table .btn-secondary {
	background-color: var(--bs-secondary);
}

.alert-dismissible .btn-close {
	z-index: 1;
}

.controls {
	z-index: 2000;
	padding-top: 15px;
	padding-bottom: 15px;
	margin-right: 10px;
}

.app-menu .nav-item {
	font-size: 0.9rem;
	padding-bottom: 0.5rem;
}

.app-menu .nav-item a {
	border-radius: 4px;
	height: 3rem;
	display: flex;
	align-items: center;
	line-height: 3rem;
	padding-left: 1rem;
}

.app-menu .nav-item a.active {
	background-color: rgba(255, 255, 255, 0.25);
	color: white;
}

.app-menu .nav-item a:hover {
	background-color: rgba(255, 255, 255, 0.1);
	color: white;
}

.app-menu .nav-link .oi {
	width: 1.5rem;
	font-size: 1.1rem;
	vertical-align: text-top;
	top: -2px;
}

.navbar-toggler {
	background-color: rgba(255, 255, 255, 0.1);
	margin: 0.5rem;
}

div.app-moduleactions a.dropdown-toggle,
div.app-moduleactions div.dropdown-menu {
	color: #000000;
}

@media (max-width: 767px) {
	.app-menu {
		width: 100%;
	}

	.controls {
		height: 60px;
		top: 15px;
		position: fixed;
		top: 0px;
		width: 100%;
		background-color: rgb(0, 0, 0);
	}

	.controls-group {
		float: right;
		margin-right: 25px;
	}

	.content {
		position: relative;
		top: 60px;
	}

	.navbar {
		padding-right: 0;
		padding-left: 0;
	}
}

.app-admin-modal .#{$prefix}-container,
.app-admin-modal,
.#{$prefix}-container.app-admin-modal {
	> .container,
	> .container-fluid {
		border-radius: 8px;
		background-color: #343a40;
		padding-top: 45px;
		padding-bottom: 45px;
		.container {
			padding: 0px;
		}
	}
	span {
		color: #a0a9c1;
	}
	.modal-header {
		border-color: #474b57;
	}
	.col-md-2 {
		display: flex;
		margin-bottom: 30px;
		a.nav-link {
			background-color: rgb(71, 75, 87);
			border-radius: 8px;
			min-width: 100%;
			box-shadow: 0 0 0 rgba(0, 0, 0, 0);
			transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;
			&:hover {
				box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
				background-color: darken(#474b57, 5%);
			}
		}
	}
	// Tab Navigation
	.nav-tabs {
		border-bottom: 1px solid rgba(71, 75, 87, 1);
		.nav-link {
			margin-bottom: -1px;
			background: none;
			border: 1px solid rgba(71, 75, 87, 0);
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
			color: #a0a9c1;
			&:hover,
			&.active {
				border-color: rgba(71, 75, 87, 1);
				color: #a0a9c1;
				background-color: darken(#343a40, 5%);
			}
		}
	}
	//Tables
	table.table-borderless {
		background-color: #474b57;
		border-radius: 8px;
		margin-top: 15px;
		thead {
			border-bottom: 1px solid #343a40;
		}
		td {
			vertical-align: middle;
		}
	}
	//END Tables

	h2 {
		color: #fff !important;
		span {
			color: #a0a9c1;
		}
	}
	.page-link,
	.page-item.disabled .page-link {
		color: #a0a9c1;
		background-color: darken(#343a40, 5%);
		border: 1px solid #474b57;
	}
	.page-item.active .page-link {
		z-index: 3;
		color: #a0a9c1;
		background-color: #0088f4;
		border: 1px solid #474b57;
	}
	.oi {
		top: 4px;
	}
	// display: flex;
	// justify-content: center;
	.form-select,
	.form-control,
	input {
		background-color: #474b57 !important;
		border: 1px solid #474b57 !important;

		color: #a0a9c1;
		border-radius: 8px;
		margin-bottom: 2px;
		&:focus {
			box-shadow: 0 0 0 0.25rem rgba(0, 136, 244, 0.8);
		}
	}
	.form-select {
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23a0a9c1%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
	}
	.control-label {
		text-align: left !important;
		color: #a0a9c1;
		display: flex;
		font-size: 0.85em;
		margin-bottom: 5px;
	}
	.btn {
		border-radius: 8px;
	}
	.btn-primary {
		background-color: #0088f4;
		border-color: #0088f4;
		color: #fff;
		&:hover {
			background-color: darken(#0088f4, 15%);
			border-color: darken(#0088f4, 15%);
			color: #fff;
		}
		&:focus {
			box-shadow: 0 0 0 0.25rem rgba(0, 136, 244, 0.8);
		}
	}
	.input-group {
		.btn-secondary {
			background-color: darken(#343a40, 5%);
			border-color: #474b57;
			color: #fff;
			margin-bottom: 2px;
			&:hover {
				background-color: lighten(#343a40, 15%);
				color: #fff;
				border-color: #474b57;
			}
			&:focus {
				box-shadow: 0 0 0 0.25rem rgba(0, 136, 244, 0.8);
			}
		}
	}
	.btn-secondary {
		background-color: darken(#343a40, 5%);
		border-color: #474b57;
		color: #fff;
		&:hover {
			background-color: lighten(#343a40, 15%);
			color: #fff;
			border-color: #474b57;
		}
		&:focus {
			box-shadow: 0 0 0 0.25rem rgba(0, 136, 244, 0.8);
		}
	}
	.Oqtane-Modules-Admin-Login {
		width: 300px;
		padding: 30px;
		background: #343a40;
		border-radius: 8px;
		margin: 0 auto;
		.form-control {
			background-color: #474b57 !important;
			border: 1px solid #474b57 !important;
			color: #a0a9c1;
			border-radius: 8px;
			margin-bottom: 2px;
			&:focus {
				box-shadow: 0 0 0 0.25rem rgba(0, 136, 244, 0.8);
			}
		}
		.form-check-input {
			background-color: #474b57 !important;
			border: 1px solid rgba(0, 136, 244, 0);
			&:focus {
				box-shadow: 0 0 0 0.25rem rgba(0, 136, 244, 0.8);
			}
		}
		span {
			color: #a0a9c1;
			font-size: 0.85em;
			.form-label {
				text-align: left !important;
				color: #a0a9c1;
				font-size: 0.85em;
				margin-bottom: 5px;
			}
		}
		img {
			width: 15px;
			height: 15px;
			margin-left: 5px;
		}
	}
}
//Offcanvas Admin
#offcanvasControlPanel {
	background: #343a40;
	.btn {
		border-radius: 8px;
	}
	.btn-primary {
		background-color: #0088f4;
		border-color: #0088f4;
		color: #fff;
		&:hover {
			background-color: darken(#0088f4, 15%);
			border-color: darken(#0088f4, 15%);
			color: #fff;
		}
	}
	.btn-secondary {
		background-color: darken(#343a40, 5%);
		border-color: #474b57;
		color: #fff;
		&:hover {
			background-color: lighten(#343a40, 15%);
			color: #fff;
			border-color: #474b57;
		}
	}
	.form-select,
	.form-control {
		background-color: #474b57 !important;
		border-color: #474b57 !important;
		color: #a0a9c1;
		border-radius: 8px;
		margin-bottom: 2px;
		&:focus {
			box-shadow: 0 0 0 0.25rem rgba(0, 136, 244, 0.8);
		}
	}
	.form-select {
		background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23a0a9c1%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e");
	}
	.control-label {
		text-align: left !important;
		color: #a0a9c1;
		display: flex;
		font-size: 0.85em;
		margin-bottom: 5px;
	}
	hr.app-rule {
		background-color: transparent;
		margin: 8px 0;
	}
	.row {
		margin-bottom: 8px;
	}
	.btn-close {
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='53.7' height='53.7' viewBox='0 0 53.7 53.7'><path opacity='.8' fill='white' d='M35.6 34.4L28 26.8l7.6-7.6c.2-.2.2-.5 0-.7l-.5-.5c-.2-.2-.5-.2-.7 0l-7.6 7.6-7.5-7.6c-.2-.2-.5-.2-.7 0l-.6.6c-.2.2-.2.5 0 .7l7.6 7.6-7.6 7.5c-.2.2-.2.5 0 .7l.5.5c.2.2.5.2.7 0l7.6-7.6 7.6 7.6c.2.2.5.2.7 0l.5-.5c.2-.2.2-.5 0-.7z'/></svg>");
		background-repeat: no-repeat;
		background-size: contain;
		cursor: pointer;
	}
}
//modal
.app-admin-modal {
	.modal-content {
		background-color: #343a40;
		a {
			h2 {
				color:white {
					span.oi {
						color: #0088f4 !important;
					}
				}
			}
		}
	}
}
//END ADMIN CMS Styling //

.table > :not(caption) > * > * {
	box-shadow: none;
}

.table .form-control {
	background-color: #ffffff !important;
	border-width: 0.5px !important;
	border-bottom-color: #ccc !important;
}

.table .form-select {
	background-color: #ffffff !important;
	border-width: 0.5px !important;
	border-bottom-color: #ccc !important;
}

.table .btn-primary {
	background-color: var(--bs-primary);
}

.table .btn-secondary {
	background-color: var(--bs-secondary);
}

.alert-dismissible .btn-close {
	z-index: 1;
}

.controls {
	z-index: 2000;
	padding-top: 15px;
	padding-bottom: 15px;
	margin-right: 10px;
}

.app-menu .nav-item {
	font-size: 0.9rem;
	padding-bottom: 0.5rem;
}

.app-menu .nav-item a {
	border-radius: 4px;
	height: 3rem;
	display: flex;
	align-items: center;
	line-height: 3rem;
	padding-left: 1rem;
}

.app-menu .nav-item a.active {
	background-color: rgba(255, 255, 255, 0.25);
	color: white;
}

.app-menu .nav-item a:hover {
	background-color: rgba(255, 255, 255, 0.1);
	color: white;
}

.app-menu .nav-link .oi {
	width: 1.5rem;
	font-size: 1.1rem;
	vertical-align: text-top;
	top: -2px;
}

.navbar-toggler {
	background-color: rgba(255, 255, 255, 0.1);
	margin: 0.5rem;
}

div.app-moduleactions a.dropdown-toggle,
div.app-moduleactions div.dropdown-menu {
	color: white;
}

@media (max-width: 767px) {
	.app-menu {
		width: 100%;
	}

	.controls {
		height: 60px;
		top: 15px;
		position: fixed;
		top: 0px;
		width: 100%;
		background-color: rgb(0, 0, 0);
	}

	.controls-group {
		float: right;
		margin-right: 25px;
	}

	.content {
		position: relative;
		top: 60px;
	}

	.navbar {
		padding-right: 0;
		padding-left: 0;
	}
}
