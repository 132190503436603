﻿.role-admin {
  nav##{$prefix}-page-navigation,
  ##{$prefix}-page-header-pane {
    .pane-is-empty {
      min-height: 50px;
    }
  }
}

nav##{$prefix}-page-navigation {
  background: transparent;
  position: fixed;
  width:100%;
  left:0px;
  top: 0;
  z-index: 800;
  padding-top: 0;
  padding-bottom: 0;

  .logo {
    display: block;
    width: 180px;
    max-width: 80%;
    height: auto;
    transition: width 0.4s ease-in-out;

    @include media-breakpoint-up(lg) {
      width: 250px;
    }

    svg {
      //If you use an SVG as Logo, you will have to give it a fixed height
      //in this selector, orelse you may experience issues in IE */
      height: 37px;

      @include media-breakpoint-up(lg) {
        height: 53px;
      }
    }
  }
}