﻿footer##{$prefix-footer} {
  ul {
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
    }

    li, li span {
      list-style-type: none;
      margin-right: 6px;
      color:#FFF;
    }
  }

  a,
  a:focus {
    color: #fff;
    text-decoration: underline;
    text-underline-offset: 3px;

    &:hover,
    &:active {
      color: rgba(#fff, 0.5);
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    &.#{$prefix-login}-invisible {
      font-size: 12px;
      color: transparent;
      cursor: pointer;

      &:hover {
        color: $white;
      }
    }
  }

  .#{$prefix-footer}-address {

  }

  .#{$prefix-footer}-imprint {
    display: flex;
    align-items: center;

    ul {
      @include media-breakpoint-up(lg) {
        margin-left: 8px;

        li {
          display:flex;
          padding:0px;
          a{
            padding:0;
          }
          &:first-child {
            margin-right:0px;
            &:before {
              content: "";
            }  
          }
    
          &:before {
            content: "|";
            padding: 0 4px;
          }
        }
      }
    }
  }
}
