@font-face {
    font-family: 'Inktrap';
    src: url('/src/styles/Fonts/Trap-Regular.woff2') format('woff2'),
        url('/src/styles/Fonts/Trap-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inktrap';
    src: url('/src/styles/Fonts/Trap-Light.woff2') format('woff2'),
        url('/src/styles/Fonts/Trap-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inktrap';
    src: url('/src/styles/Fonts/Trap-Bold.woff2') format('woff2'),
        url('/src/styles/Fonts/Trap-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

